import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { motion } from 'framer-motion';
import { links, footerLinks } from './data';
import { perspective, slideIn } from './anim';
import './Nav.css';

const Nav = ({ setIsActive }) => {

    const [hiddenClassmobile, setHiddenClassmobile] = useState('');

    useEffect(() => {
        const handleResizemobile = () => {
            if (window.innerWidth < 767) {
                setHiddenClassmobile('hidden');
            } else {
                setHiddenClassmobile('');
            }
        };

        window.addEventListener('resize', handleResizemobile);
        handleResizemobile(); // Initial check

        return () => {
            window.removeEventListener('resize', handleResizemobile);
        };
    }, []);

    const handleClick = () => {
        setIsActive(false);
    };

    const handleFooterClick = (title) => {
        switch (title) {
            case 'LinkedIn':
                window.open('https://www.linkedin.com/company/genzix/about/', '_blank', 'noopener,noreferrer');
                break;
            case 'Instagram':
                window.open('https://www.instagram.com/genzix.in/', '_blank', 'noopener,noreferrer');
                break;
            default:
                break;
        }
    };

    return (
        <div className='nav'>
            <div className='body' style={{ marginTop: '20vh' }}>
                {links.map((link, i) => {
                    const { title, href } = link;
                    return (
                        <div key={`b_${i}`} className='linkContainer'>
                            <motion.div
                                custom={i}
                                variants={perspective}
                                initial='initial'
                                animate='enter'
                                exit='exit'
                            >
                                <NavLink to={href} activeClassName='active' onClick={handleClick}>
                                    {title}
                                </NavLink>
                            </motion.div>
                        </div>
                    );
                })}
            </div>
            <div style={{ position: 'absolute', marginTop:hiddenClassmobile ? '75vh':'70vh', display: 'flex', gap: '10vw', marginLeft: '10%' }}>
                {footerLinks.map((link, i) => {
                    const { title, href } = link;
                    return (
                        <motion.div
                            variants={slideIn}
                            custom={i}
                            initial='initial'
                            animate='enter'
                            exit='exit'
                            key={`f_${i}`}
                            onClick={() => handleFooterClick(title)}
                            style={{ cursor: 'pointer', fontSize: hiddenClassmobile ? '4vh' :'3.5vh', fontWeight:'400' }}
                        >
                            {title}
                        </motion.div>
                    );
                })}
            </div>
        </div>
    );
};

export default Nav;
