import React, { useEffect } from 'react'
import Project from '../components/project'
import Projectgalle from '../components/projectgalle'
import Contact from '../components/contact'

const Projectpage = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div>
            <div style={{ height: '20vh', background: '#EDE9E3' }}></div>
            <Project />
            <Projectgalle />
            <Contact />
        </div>
    )
}

export default Projectpage
