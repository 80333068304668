import React, { useEffect } from 'react'
import Srolltext from '../components/Srolltext'
import Abouthome from '../components/abouthome'
import Contact from '../components/contact'

const Aboutus = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div>
            <div style={{ height: '10vh', background: '#EDE9E3' }}></div>
            <Srolltext />
            <Abouthome />
            <Contact />
        </div>
    )
}

export default Aboutus
