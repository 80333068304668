export const links = [
    {
        title: "Home",
        href: "/"
    },
    {
        title: "Portfolio",
        href: "/projects"
    },
    {
        title: "Services",
        href: "/services"
    },
    {
        title: "About Us",
        href: "/aboutus"
    },
    {
        title: "Contact",
        href: "/contactus"
    }
]

export const footerLinks = [
    {
        title: "LinkedIn",
        // no onClick property here
    },
    {
        title: "Instagram",
        // no onClick property here
    },
];
