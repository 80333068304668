import React, { useState, useEffect } from 'react';
import styled from 'styled-components';


const CustomStyle = styled.div`
  min-height: 25vh;
  width: 100%;
  background: #FFCF96;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: start;
`;

const CustomStyle1 = styled.div`
  height: 100%;
  width: 100%;
  background: #FFCF96;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  justify-content: start;
`;


const Fotter = () => {

    const [localTime, setLocalTime] = useState('');
    const [hiddenClass, setHiddenClass] = useState('');
    const [hiddenClassmobile, setHiddenClassmobile] = useState('');

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 1200) {
                setHiddenClass('hidden');
            } else {
                setHiddenClass('');
            }
        };

        window.addEventListener('resize', handleResize);
        handleResize(); // Initial check

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        const handleResizemobile = () => {
            if (window.innerWidth < 767) {
                setHiddenClassmobile('hidden');
            } else {
                setHiddenClassmobile('');
            }
        };

        window.addEventListener('resize', handleResizemobile);
        handleResizemobile(); // Initial check

        return () => {
            window.removeEventListener('resize', handleResizemobile);
        };
    }, []);

    const updateLocalTime = () => {
        const now = new Date();
        const options = { timeZone: 'Asia/Kolkata', hour: '2-digit', minute: '2-digit', hour12: true };
        const timeString = now.toLocaleTimeString('en-US', options);
        setLocalTime(timeString);
    };

    useEffect(() => {
        updateLocalTime();
        const intervalId = setInterval(updateLocalTime, 60000); // Update time every minute

        return () => clearInterval(intervalId); // Cleanup interval on component unmount
    }, []);

    const handleClick = () => {
        window.open('https://www.linkedin.com/company/genzix.in/about/', '_blank', 'noopener,noreferrer');
    };

    const handleClick1 = () => {
        window.open('https://www.instagram.com/genzix.in/', '_blank', 'noopener,noreferrer');
    };

    return (
        <CustomStyle>
            <CustomStyle1>
                <text className='para4' style={{ marginLeft: 'auto', marginRight: 'auto', color: 'grey', fontSize: '14px', marginTop: '2%' }}> 2024 © Edition</text>
                {hiddenClass ? (
                    <div style={{ width: '90%', marginLeft: 'auto', marginRight: 'auto', justifyContent: 'space-between', display: 'flex', marginTop: '5%', marginBottom: '5%' }}>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <span className='aboutfotter' style={{ fontSize: hiddenClassmobile ? '12px' : '18px' }} >Email</span>
                                <span className='para5' style={{ color: '#000', marginTop: '2%', fontSize: hiddenClassmobile ? '12px' : '20px' }}>teamgenzix@gmail.com</span>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column', marginTop: '5%' }}>
                                <span className='aboutfotter' style={{ fontSize: hiddenClassmobile ? '12px' : '18px' }} >Phone no</span>
                                <span className='para5' style={{ color: '#000', marginTop: '2%', fontSize: hiddenClassmobile ? '12px' : '20px' }}>+91-9381545215</span>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column', marginTop: '5%' }}>
                                <span className='aboutfotter' style={{ fontSize: hiddenClassmobile ? '12px' : '18px' }} >Local Time</span>
                                <span className='para5' style={{ color: '#000', marginTop: '2%', fontSize: hiddenClassmobile ? '12px' : '20px' }}>{localTime} IST</span>
                            </div>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <span className='aboutfotter' style={{ fontSize: hiddenClassmobile ? '12px' : '18px' }} >Socials</span>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <span className='para5' style={{ color: '#000', marginTop: '5%', fontSize: hiddenClassmobile ? '12px' : '20px' , cursor:'pointer'}} onClick={handleClick1}> Instagram</span>
                                <span className='para5' style={{ color: '#000', marginTop: '5%', fontSize: hiddenClassmobile ? '12px' : '20px' , cursor:'pointer'}} onClick={handleClick}> LinkedIn</span>
                            </div>

                        </div>
                    </div>
                ) : (
                    <div style={{ width: "90%", height: '40%', marginTop: '2%', display: 'flex', marginLeft: 'auto', marginRight: 'auto' }}>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <span className='aboutfotter' >Email</span>
                            <span className='para5' style={{ color: '#000', marginTop: '4%' }}>teamgenzix@gmail.com</span>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', marginLeft: "2%" }}>
                            <span className='aboutfotter' >Phone no</span>
                            <span className='para5' style={{ color: '#000', marginTop: '5%' }}>+91-9381545215</span>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', marginLeft: "2%" }}>
                            <span className='aboutfotter' >Local Time</span>
                            <span className='para5' style={{ color: '#000', marginTop: '6%' }}>{localTime} IST</span>
                        </div>
                        <div style={{ marginLeft: 'auto', display: 'flex', flexDirection: 'column' }}>
                            <span className='aboutfotter' >Socials</span>
                            <div style={{ display: 'flex' }}>
                                <span className='para5' style={{ color: '#000', marginTop: '3%', cursor:'pointer' }}  onClick={handleClick1}> Instagram</span>
                                <span className='para5' style={{ color: '#000', marginTop: '3%', marginLeft: '20%' , cursor:'pointer'}}  onClick={handleClick}> LinkedIn</span>

                            </div>

                        </div>
                    </div>
                )}
            </CustomStyle1>
        </CustomStyle >
    )
}

export default Fotter
