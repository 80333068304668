import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styled from 'styled-components';

const FileUploader = () => {
    const [file, setFile] = useState(null);
    const [batchSize, setBatchSize] = useState(2);
    const [interval, setInterval] = useState(30);
    const [emailSubject, setEmailSubject] = useState('');
    const [emailText, setEmailText] = useState('');
    const [emailHtml, setEmailHtml] = useState('');
    const [loading, setLoading] = useState(false);
    const [niche, setNiche] = useState('');
    const [niches, setNiches] = useState([]);
    const [settingsVisible, setSettingsVisible] = useState(false);

    useEffect(() => {
        // Fetch available niches from the backend
        const fetchNiches = async () => {
            try {
                const response = await axios.get('http://localhost:4000/niches');
                setNiches(response.data);
            } catch (error) {
                console.error('Error fetching niches:', error);
            }
        };

        if (settingsVisible) {
            fetchNiches();
        }
    }, [settingsVisible]);

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    const handleUpload = async () => {
        if (!file) {
            alert('Please select a file first');
            return;
        }

        const formData = new FormData();
        formData.append('file', file);

        setLoading(true);

        try {
            const response = await axios.post('http://localhost:4000/upload', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            setNiches(response.data.niches); // Set niches from response
            setSettingsVisible(true); // Enable settings after upload
            alert('File uploaded successfully');
        } catch (error) {
            console.error('Error uploading file:', error);
            alert('Error uploading file');
        } finally {
            setLoading(false);
        }
    };

    const handleSettingsUpdate = async () => {
        const template = {
            subject: emailSubject,
            text: emailText,
            html: emailHtml,
        };

        try {
            const response = await axios.post('http://localhost:4000/settings', {
                batchSize,
                interval,
                template
            });
            console.log(response.data);
            alert('Settings updated successfully');
        } catch (error) {
            console.error('Error updating settings:', error);
            alert('Error updating settings');
        }
    };

    const handleNicheChange = async (e) => {
        const selectedNiche = e.target.value;
        setNiche(selectedNiche);

        try {
            const response = await axios.post('http://localhost:4000/niche', { niche: selectedNiche });
            console.log(response.data);
            alert('Niche updated successfully');
        } catch (error) {
            console.error('Error updating niche:', error);
            alert('Error updating niche');
        }
    };

    return (
        <Container>
            <Header style={{ marginTop: '15vh' }}>Upload Excel File</Header>
            <FileInput
                type="file"
                accept=".xlsx"
                onChange={handleFileChange}
            />
            <UploadButton onClick={handleUpload}>Upload File</UploadButton>

            {loading && (
                <LoadingOverlay>
                    <Spinner />
                </LoadingOverlay>
            )}

            {settingsVisible && (
                <>
                    <NicheSettings>
                        <h3>Select Niche</h3>
                        <NicheSelect value={niche} onChange={handleNicheChange}>
                            <option value="">Select a niche</option>
                            {niches.map((nicheOption) => (
                                <option key={nicheOption} value={nicheOption}>{nicheOption}</option>
                            ))}
                        </NicheSelect>
                    </NicheSettings>
                    <Settings>
                        <h3>Update Email Settings</h3>
                        <Label>
                            Batch Size:
                            <SettingsInput
                                type="number"
                                value={batchSize}
                                onChange={(e) => setBatchSize(parseInt(e.target.value, 10))}
                                min="1"
                            />
                        </Label>
                        <br />
                        <Label>
                            Interval (minutes):
                            <SettingsInput
                                type="number"
                                value={interval}
                                onChange={(e) => setInterval(parseInt(e.target.value, 10))}
                                min="1"
                            />
                        </Label>
                        <br />
                        <Label>
                            Email Subject:
                            <SettingsInput
                                type="text"
                                value={emailSubject}
                                onChange={(e) => setEmailSubject(e.target.value)}
                            />
                        </Label>
                        <br />
                        <Label>
                            Email Text Template:
                            <SettingsInput
                                as="textarea"
                                rows="4"
                                value={emailText}
                                onChange={(e) => setEmailText(e.target.value)}
                            />
                        </Label>

                        <UpdateButton onClick={handleSettingsUpdate}>Update Settings</UpdateButton>
                    </Settings>
                </>
            )}
        </Container>
    );
};

const Container = styled.div`
min-height: 100vh;
    padding: 20px;
    max-width: 600px;
    margin: 0 auto;
`;

const Header = styled.h2`
    text-align: center;
`;

const FileInput = styled.input`
    margin-bottom: 10px;
`;

const UploadButton = styled.button`
    display: block;
    margin: 10px auto;
    padding: 10px;
`;

const NicheSettings = styled.div`
    margin-top: 20px;
`;

const NicheSelect = styled.select`
    width: 100%;
    padding: 10px;
`;

const Settings = styled.div`
    margin-top: 20px;
`;

const Label = styled.label`
    display: block;
    margin-bottom: 10px;
`;

const SettingsInput = styled.input`
    width: 100%;
    padding: 10px;
`;

const UpdateButton = styled.button`
    display: block;
    margin-top: 10px;
    padding: 10px;
`;

const LoadingOverlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
`;

const Spinner = styled.div`
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-left: 4px solid #000;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;

    @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    }
`;

export default FileUploader;
