import React, { useEffect } from 'react'
import Services from '../components/services'
import Contact from '../components/contact'

const Sevicespage = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div>
            <div style={{ height: '15vh', background: '#EDE9E3' }}></div>
            <Services />
            <Contact />
        </div>
    )
}

export default Sevicespage
