'use client';
import React, { useRef } from 'react';
import gsap from 'gsap';
import styled from 'styled-components';
import Word from './Word';
import './Scrolltext.css';
import img from '../components/assets/color.png';

const paragraph = "At GenZix, we create seamless digital experiences using React, Next.js, Flutter, TypeScript, Node.js, AWS, and MongoDB. With years of experience, our team delivers high-performance, user-centric websites and apps. We stay updated with tech trends to transform your ideas into digital realities. Choose GenZix for exceptional web and app design, development, and digital marketing.";


const Image = styled.img`
   width: 9%;
    @media (max-width: 767px) {
       width: 20vw;
    }

    @media (min-width: 768px) and (max-width: 1024px) {
       width: 20%;
    }
`;


const Srolltext = () => {
    const plane1 = useRef(null);
    const plane2 = useRef(null);
    const plane3 = useRef(null);
    let requestAnimationFrameId = null;

    let xForce = 0;
    let yForce = 0;

    const easing = 0.08;
    const speed = 0.02;

    const manageMouseMove = (e) => {
        const { movementX, movementY } = e;

        xForce += movementX * speed;
        yForce += movementY * speed;

        if (requestAnimationFrameId == null) {
            requestAnimationFrameId = requestAnimationFrame(animate);
        }
    };

    const lerp = (start, target, amount) => start * (1 - amount) + target * amount;

    const animate = () => {
        xForce = lerp(xForce, 0, easing);
        yForce = lerp(yForce, 0, easing);

        gsap.set(plane1.current, { x: `+=${xForce}`, y: `+=${yForce}` });
        gsap.set(plane2.current, { x: `+=${xForce * 0.5}`, y: `+=${yForce * 0.5}` });
        gsap.set(plane3.current, { x: `+=${xForce * 0.25}`, y: `+=${yForce * 0.25}` });

        if (Math.abs(xForce) < 0.01) xForce = 0;
        if (Math.abs(yForce) < 0.01) yForce = 0;

        if (xForce !== 0 || yForce !== 0) {
            requestAnimationFrame(animate);
        } else {
            cancelAnimationFrame(requestAnimationFrameId);
            requestAnimationFrameId = null;
        }
    };

    return (
        <div className='scrollhome' onMouseMove={manageMouseMove} id='aboutus'>
            <div ref={plane1} className='plane'>
                <Image src={img} alt='' width='9%' />
            </div>
            <Word value={paragraph}  />
        </div>
    );
};

export default Srolltext;
