import React, { useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import './Contactuspage.css';
import img1 from '../components/assets/arrowhome.png';

// CSS for loading spinner
const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it covers everything else */
`;

const Spinner = styled.div`
  border: 8px solid #f3f3f3;
  border-top: 8px solid #FFCF96;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: ${spin} 2s linear infinite;
`;

const CustomStyle = styled.div`
  width: 100%;
  background: #EDE9E3;
  display: flex;
  flex-direction: column;
  justify-content: start;
`;

const CustomStyle1 = styled.div`
  width: 61.11%;
  background: #EDE9E3;
  display: flex;
  overflow : hidden;
  flex-direction: column;
  justify-content: start;
  @media  (max-width: 1200px) {
       display : none;
    }

`;

const CustomStyle2 = styled.div`
  width: 33.333%;
  background: #EDE9E3;
  display: flex;
  flex-direction: column;
  justify-content: start;
   @media  (max-width: 1200px) {
       display : none;
    }
`;



const CustomStyle3 = styled.div`
  height: 15vh;
  width: 100%;
  color: #000;
  background-color: #EDE9E3;
  border: 1px solid #000;
  display: flex;
  flex-direction: column;
  justify-content: start;
  border-radius: 20vh;
  font-size: 1.5vw;
  font-family: "Poppins", sans-serif;
  letter-spacing: 4px;
  font-weight: 200;
  text-decoration: none;
  text-transform: uppercase;
  display: flex;
  align-items: center; 
  justify-content: center;
`;
const StyledButton2 = styled.div`
  height: 15vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  border-radius: 100px;
  font-size: 1.5vw;
  font-family: "Poppins", sans-serif;
  letter-spacing: 4px;
  font-weight: 200;
  text-decoration: none;
  text-transform: uppercase;
  display: flex;
  align-items: center; 
  justify-content: center;
    background-color: ${({ backgroundColor }) => backgroundColor};
    color: ${({ color }) => color};
    transform: ${props => (props.isHovered ? 'translateY(-15vh)' : 'translateY(0)')};
    transition:  transform 0.2s linear;

    @media (min-width: 768px) and (max-width: 1200px) {
    font-size: 3.5vw;
  }

  @media (max-width: 767px) {
    font-size: 5vw;
  }
`;

const StyledButton3 = styled.div`
height: 15vh;
width: 100%;
display: flex;
flex-direction: column;
justify-content: start;
border-radius: 100px;
font-size: 1.5vw;
font-family: "Poppins", sans-serif;
letter-spacing: 4px;
font-weight: 200;
text-decoration: none;
text-transform: uppercase;
display: flex;
align-items: center; 
justify-content: center;
  background-color: ${({ backgroundColor }) => backgroundColor};
  color: ${({ color }) => color};
  transform: ${props => (props.isHovered ? 'translateY(-15vh)' : 'translateY(0)')};
  transition:  transform 0.2s linear;
   @media (min-width: 768px) and (max-width: 1200px) {
    font-size: 3.5vw;
  }

  @media (max-width: 767px) {
    font-size: 5vw;
  }
`;

const scrollText = keyframes`
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-34%);
  }
`;

const ScrollingText = styled.div`
  white-space: nowrap;
  display: inline-block;
  animation: ${scrollText} 8s linear infinite;
`;

const RotatedImage = styled.img`
  transform: rotate(90deg);
  height: 3vh;
`;


const StyledInput = styled.input`
  height: 15vh;
  width: 100%;
  padding: 0vh 4vh;
  background: #EDE9E3;
  border: 1px solid #000;
  border-radius: 20vh;
  font-size: 1.5vw;
  font-family: "Poppins", sans-serif;
  letter-spacing: 2px;
  font-weight: 200;
  outline: none;
  color: #000;
  display: flex;
  align-items: center;
  justify-content: center;

  &::placeholder {
    color: #8F8D8D;
    font-size: 1.5vw;
    text-transform: none;
  }

  @media (min-width: 768px) and (max-width: 1200px) {
    font-size: 3.5vw;
    width: 90vw;
    &::placeholder {
      font-size: 3.5vw;
    }
  }

  @media (max-width: 767px) {
    font-size: 5vw;
    width: 90vw;
    &::placeholder {
      font-size: 5vw;
    }
  }
`;


const StyledContainer = styled.div`
  width: 90vw;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  justify-content: start;
`;

const StyledContainer1 = styled.div`
  width: 100%;
  height: 15vh;
  display: flex;
  align-items: end;
  justify-content: start;
   @media (max-width: 767px) {
       height: auto;
        
    }

    @media (min-width: 768px) and (max-width: 1200px) {
        height: auto;
    }

`;
const StyledContainer2 = styled.div`
  width: 100%;
  height: 15vh;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 767px) {
       height: 8vh;
        
    }

    @media (min-width: 768px) and (max-width: 1200px) {
        height: 8vh;
    }
`;

const Why = styled.div`
  font-size: 3.5vw;
  color: #000;
  font-weight: 600;
  letter-spacing: 1px;
  margin-bottom: 1vw;
  font-family: "Darker Grotesque", sans-serif;
  line-height: 1;

   @media (min-width: 768px) and (max-width: 1200px) {
      font-size: 8vw;
      margin-bottom: 2vw;
    }
    @media (max-width: 767px) {
    font-size: 10vw;
     margin-bottom: 2vw;
    }
`;

const About2 = styled.div`
  font-size: 2vw;
  line-height: 1;
  margin-bottom: 1.2vw;
  margin-left: 1vw;
  color: #000;
  text-transform: uppercase;
  font-family: "Darker Grotesque", sans-serif;
  letter-spacing: 2px;
  font-weight: 700;
   @media (min-width: 768px) and (max-width: 1200px) {
      font-size: 2.7vw;
      margin-bottom: 2.8vw;
     margin-left: 2vw;
    }
    @media (max-width: 767px) {
    font-size: 3vw;
    margin-bottom: 3vw;
    margin-left: 2vw;
    }
`;

const Contactuspage = () => {
    const [isHovered5, setIsHovered5] = useState(false);
    const [hiddenClass, setHiddenClass] = useState('');
    const [hiddenClassmobile, setHiddenClassmobile] = useState('');


    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        const handleResize = () => {
            setHiddenClass(window.innerWidth < 1200 ? 'hidden' : '');
            setHiddenClassmobile(window.innerWidth < 767 ? 'hidden' : '');
        };

        window.addEventListener('resize', handleResize);
        handleResize(); // Initial check

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        company: '',
        services: '',
        phoneno: '',
        message: ''
    });
    const [loading, setLoading] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        // Check for missing fields and display individual alerts
        if (!formData.name) {
            alert('Please enter your name.');
            setLoading(false);
            return;
        }
        if (!formData.email) {
            alert('Please enter your email.');
            setLoading(false);
            return;
        }
        if (!formData.company) {
            alert('Please enter your company.');
            setLoading(false);
            return;
        }
        if (!formData.services) {
            alert('Please select your services.');
            setLoading(false);
            return;
        }
        if (!formData.phoneno) {
            alert('Please enter your phone number.');
            setLoading(false);
            return;
        }
        if (!formData.message) {
            alert('Please enter your message.');
            setLoading(false);
            return;
        }

        try {
            const response = await fetch('http://localhost:4000/submitFormToMongoDB', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formData)
            });

            const result = await response.json();

            if (response.ok) {
                alert('Thank you for your consideration. We will get back to you shortly.');
                setFormData({
                    name: '',
                    email: '',
                    company: '',
                    services: '',
                    phoneno: '',
                    message: ''
                });
            } else {
                alert(`Error: ${result.error}`);
            }
        } catch (error) {
            console.error('Error submitting form:', error);
            alert('There was an error submitting the form.');
        } finally {
            setLoading(false);
        }
    };


    return (
        <CustomStyle>
            {loading && (
                <Overlay>
                    <Spinner />
                </Overlay>
            )}
            <StyledContainer >
                <Why style={{ marginLeft: 'auto', marginRight: 'auto', marginTop: '20vh', textAlign: 'center' }} >Let's  start  a project together</Why>
                <div style={{ marginTop: '3vh', display: 'flex', justifyContent: 'space-between', height: 'auto' }}>

                    {hiddenClass ? (
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <StyledContainer1 >
                                <Why >01</Why>
                                <About2  >Name</About2>
                            </StyledContainer1>
                            <StyledInput
                                name='name'
                                type="text"
                                placeholder='Madhuri *'
                                value={formData.name}
                                onChange={handleChange}
                                required
                            />
                            <StyledContainer1 >
                                <Why >02</Why>
                                <About2  >Email</About2>
                            </StyledContainer1>
                            <StyledInput
                                name='email'
                                type="email"
                                placeholder='eroberteam@gmail.com *'
                                value={formData.email}
                                onChange={handleChange}
                                required
                            />
                            <StyledContainer1 >
                                <Why >03</Why>
                                <About2  >What's the name of your organization?</About2>
                            </StyledContainer1>
                            <StyledInput
                                name='company'
                                type="text"
                                placeholder='Genzix *'
                                value={formData.company}
                                onChange={handleChange}
                                required
                            />
                            <StyledContainer1 >
                                <Why >04</Why>
                                <About2  >What services are you looking for?</About2>
                            </StyledContainer1>
                            <StyledInput
                                name='services'
                                type="text"
                                placeholder='Web & app design and development and digital marketing.... *'
                                value={formData.services}
                                onChange={handleChange}
                                required
                            />
                            <StyledContainer1 >
                                <Why >05</Why>
                                <About2  >Your Phone Number</About2>
                            </StyledContainer1>
                            <StyledInput
                                type="text"
                                name="phoneno"
                                placeholder="Phone no with code"
                                value={formData.phoneno}
                                onChange={handleChange}
                                required
                            />
                            <StyledContainer1 >
                                <Why >06</Why>
                                <About2  >Your message</About2>
                            </StyledContainer1>
                            <StyledInput
                                name='message'
                                type="text"
                                placeholder='Hello Genzix, can you help me... *'
                                value={formData.message}
                                onChange={handleChange}
                                required
                            />
                            <StyledContainer2 >
                            </StyledContainer2>
                        </div>
                    ) : (
                        <CustomStyle1>
                            <StyledContainer1 >
                                <Why >01</Why>
                                <About2  >Name</About2>
                            </StyledContainer1>
                            <StyledInput
                                name='name'
                                type="text"
                                placeholder='Madhuri *'
                                value={formData.name}
                                onChange={handleChange}
                                required
                            />
                            <StyledContainer1 >
                                <Why >02</Why>
                                <About2  >Email</About2>
                            </StyledContainer1>
                            <StyledInput
                                name='email'
                                type="email"
                                placeholder='eroberteam@gmail.com *'
                                value={formData.email}
                                onChange={handleChange}
                                required
                            />
                            <StyledContainer1 >
                                <Why >03</Why>
                                <About2  >What's the name of your organization?</About2>
                            </StyledContainer1>
                            <StyledInput
                                name='company'
                                type="text"
                                placeholder='Genzix *'
                                value={formData.company}
                                onChange={handleChange}
                                required
                            />
                            <StyledContainer1 >
                                <Why >04</Why>
                                <About2  >What services are you looking for?</About2>
                            </StyledContainer1>
                            <StyledInput
                                name='services'
                                type="text"
                                placeholder='Web & app design and development and digital marketing.... *'
                                value={formData.services}
                                onChange={handleChange}
                                required
                            />
                            <StyledContainer1 >
                                <Why >05</Why>
                                <About2  >Your Phone Number </About2>
                            </StyledContainer1>
                            <StyledInput
                                name="phoneno"
                                placeholder="Phone no with code"
                                type="text"
                                value={formData.phoneno}
                                onChange={handleChange}
                                required
                            />
                            <StyledContainer1 >
                                <Why >06</Why>
                                <About2  >Your message</About2>
                            </StyledContainer1>
                            <StyledInput
                                name='message'
                                type="text"
                                placeholder='Hello Genzix, can you help me... *'
                                value={formData.message}
                                onChange={handleChange}
                                required
                            />
                            <StyledContainer2 >
                            </StyledContainer2>
                        </CustomStyle1>
                    )
                    }
                    <CustomStyle2>
                        <StyledContainer2 >
                        </StyledContainer2>
                        <CustomStyle3 style={{ background: "#FFCF96" }}>Form Filling</CustomStyle3>
                        <StyledContainer2 >
                            <RotatedImage src={img1} alt='' />
                        </StyledContainer2>
                        <CustomStyle3>Kick Off Meeting</CustomStyle3>
                        <StyledContainer2 >
                            <RotatedImage src={img1} alt='' />
                        </StyledContainer2>
                        <CustomStyle3>Design Review</CustomStyle3>
                        <StyledContainer2 >
                            <RotatedImage src={img1} alt='' />
                        </StyledContainer2>
                        <CustomStyle3>Development</CustomStyle3>
                        <StyledContainer2 >
                            <RotatedImage src={img1} alt='' />
                        </StyledContainer2>
                        <CustomStyle3>Review and Testing</CustomStyle3>
                        <StyledContainer2 >
                            <RotatedImage src={img1} alt='' />
                        </StyledContainer2>
                        <CustomStyle3>Digital Marketing</CustomStyle3>
                    </CustomStyle2>
                </div >
                <CustomStyle3 onClick={handleSubmit} style={{ cursor: 'pointer', position: 'relative', overflow: 'hidden' }} onMouseEnter={() => setIsHovered5(true)} onMouseLeave={() => setIsHovered5(false)} isHovered={isHovered5} >
                    <StyledButton2 backgroundColor="#EDE9E3" color="#fff" isHovered={isHovered5}>
                        <text style={{ color: '#000' }}>submit</text>
                    </StyledButton2>
                    <StyledButton3 backgroundColor="#FFCF96" color="#000" isHovered={isHovered5} style={{ position: 'absolute', left: '0', marginTop: '30vh', zIndex: '1' }}>
                        <text style={{ color: '#000' }}>submit</text>
                    </StyledButton3>
                </CustomStyle3>
            </StyledContainer >
            <StyledContainer2 >
            </StyledContainer2>
        </CustomStyle >
    )
}

export default Contactuspage


